





















































































































































import Vue from 'vue';
import { mapGetters } from 'vuex';
import ImageDisplayCondition from '@/types/commentaryAnalysisHistory';
import CandidatesOfCommentaryInstructDialog from '@/components/commentaryAnalysis/report/CandidatesOfCommentaryInstructDialog.vue';
import CandidatesOfCorrectCommentaryDialog from '@/components/commentaryAnalysis/report/CandidatesOfCorrectCommentaryDialog.vue';
import CandidatesOfDrivingSpeedInstructDialog from '@/components/commentaryAnalysis/report/CandidatesOfDrivingSpeedInstructDialog.vue';
import CandidatesOfDrivingCautionDialog from '@/components/commentaryAnalysis/report/CandidatesOfDrivingCautionDialog.vue';

export default Vue.extend({
  components: {
    CandidatesOfCommentaryInstructDialog,
    CandidatesOfCorrectCommentaryDialog,
    CandidatesOfDrivingSpeedInstructDialog,
    CandidatesOfDrivingCautionDialog,
  },
  props: ['reportProps', 'index'],
  data: () => ({
    kindLabelMap: {
      car: '車',
      person: '歩行者',
      bicycle: '自転車',
      motorcycle: '二輪車',
      truck: 'トラック',
      bus: 'バス',
      traffic_light: '信号機',
      check: '呼称',
      prefix_check: '呼称 前',
      suffix_check: '呼称 後',
    },
    clientComment: {},
    content: {
      input1: '',
      input2: '',
      input3: '',
      input4: '',
      input5: '',
      isGenerating: false,
    } as any,
    rules1: [
      (value: any) => (value || '').length <= 60 || '文字数が多いです。印刷時に枠からはみ出す可能性があります。',
    ] as any,
    rules2: [
      (value: any) => (value || '').length <= 180 || '文字数が多いです。印刷時に枠からはみ出す可能性があります。',
    ] as any,
  }),
  computed: {
    ...mapGetters('CommentaryAnalysisHistoriesModule', [
      'commentaryAnalysisHistories',
    ]),
    comment: function() {
      const history = this.commentaryAnalysisHistories[0];
      const index = history.imageDisplayConditions.findIndex(
        (image: ImageDisplayCondition) =>
          image.time === this.reportProps.comment.time &&
          image.kind == this.reportProps.comment.kind
      );

      if (index === -1 && this.reportProps.comment.commented) return null;

      if (index > -1 && !history.imageDisplayConditions[index].isShow)
        return null;

      return this.reportProps.comment;
    },
  },
  methods: {
    isCheckCommentary(kind: string) {
      return ['check', 'prefix_check', 'suffix_check'].indexOf(kind) > -1;
    },
    changeText() {
      this.$store.dispatch(
        'CommentaryAnalysisModule/updateGuidancePointComment',
        { index: this.reportProps.index, content: this.content }
      );
    },
    choseCandidate(value: any, name: string) {
      this.content[name] = value;
      (this as any).changeText()
    },

    handlekeydown(e: any) {
      if (
        e.target.value.length >= 200 &&
        ![8, 37, 38, 39, 40, 46].includes(e.keyCode)
      ) {
        e.preventDefault();
      }
    },
    async getInstruction(image_url: string) {
      this.content.isGenerating = true
      const response = await this.$store.dispatch(
        'CommentaryAnalysisModule/generateInstruction', { image_url: image_url }
      );

      if (response !== '') {
        this.content.input1 = response.instruction.Instruction_points_for_commentary;
        this.content.input4 = response.instruction.Guidance_points_for_speed;
        this.content.input5 = response.instruction.Points_to_note;
        this.content.isGenerating = false;
        (this as any).changeText()
      }
    },
  },
});
