












































import Vue from 'vue';
import ImageDisplayCondition from '@/types/commentaryAnalysisHistory';
import CommentaryAnalysisHistory from '@/types/commentaryAnalysisHistory';
import Commentaries from '@/types/commentary';

export default Vue.extend({
  props: ['objProps'],
  data: () => ({
    styleObject: {
      marginLeft: 'auto',
      marginRight: '50px',
    },
    isShow: true,
    historyDetail: {} as CommentaryAnalysisHistory,
    comment: {} as Commentaries,
  }),
  created() {
    this.comment = this.objProps.comment;
    this.historyDetail = this.objProps.history;
    const index = this.objProps.history.imageDisplayConditions.findIndex(
      (image: ImageDisplayCondition) =>
        image.time === this.objProps.comment.time &&
        image.kind == this.objProps.comment.kind
    );
    if (index > -1) {
      this.isShow = this.objProps.history.imageDisplayConditions[index].isShow;
    } else {
      this.isShow = !this.objProps.comment.commented;
    }
  },
  methods: {
    isCheckCommentary(kind: string) {
      return ['check', 'prefix_check', 'suffix_check'].indexOf(kind) > -1;
    },
    switchEvent() {
      if (this.historyDetail.imageDisplayConditions) {
        const index = this.historyDetail.imageDisplayConditions.findIndex(
          (image: ImageDisplayCondition) =>
            image.time === this.comment.time && image.kind == this.comment.kind
        );
        if (index > -1) {
          this.historyDetail.imageDisplayConditions[index].isShow = this.isShow;
        } else {
          this.historyDetail.imageDisplayConditions.push({
            kind: this.comment.kind,
            isShow: this.isShow,
            time: this.comment.time,
          } as ImageDisplayCondition);
        }
      } else {
        this.historyDetail.imageDisplayConditions = [
          {
            kind: this.comment.kind,
            isShow: this.isShow,
            time: this.comment.time,
          } as ImageDisplayCondition,
        ];
      }
      this.$emit('event', this.historyDetail);
    },
  },
});
